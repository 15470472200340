$(function(){
    /**
     * Hero számokhoz tartozó tartalmak megjelenítése elrejtése
     **/
    $(".hero-nums li").each(function(){
        $(this).on('click', function(){
            $(this).addClass("active");
            $(this).siblings().removeClass("active");
            var numClass = $(this).data('num');
            $(".hero figcaption").find("."+numClass).show();
            $(".hero figcaption p:not(."+numClass+")").hide();
        });

    });

    /**
     * Desktop navigáció
     **/
    $(".header-menu ul").find('a').on('click', function (e) {
        e.preventDefault();
        var eHref = $(this).attr("href").split('/')[1];
        $('html, body').animate({
            scrollTop: $(""+ eHref +"").offset().top
        }, 1200);
    });

    $("details").on('click', function(e){
        e.preventDefault();
    });
});
