$(function() {
    var moduleClass = ".map";
    var mapInstances = []; // map példányok tömbje
    var mapIcon =  "/assets/img/layout/icons/map-pin.png";

    var mapStyle = [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ];

    function isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    }
    function hasMapOnPage() {
        if ($(moduleClass).length) {
            return true;
        }
        return false;
    };

    function setup() {
        $("#map").lazyLoadGoogleMaps({

            key: "AIzaSyCBinyPNMDGgFe-hbo-MVBrJ1AzxBkCWu0",
            callback: function (container, map) {

                var isDraggable = !isMobile();

                google.maps.Map.prototype.setCenterWithOffset = function (latlng, offsetX, offsetY) {
                    var map = this;
                    var ov = new google.maps.OverlayView();
                    ov.onAdd = function () {
                        var proj = this.getProjection();
                        var aPoint = proj.fromLatLngToContainerPixel(latlng);
                        aPoint.x = aPoint.x + offsetX;
                        aPoint.y = aPoint.y + offsetY;
                        map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
                    };
                    ov.draw = function () {
                    };
                    ov.setMap(this);
                };

                var center =  new google.maps.LatLng(46.085007, 18.210947);
                // var center = {lat: 46.085007, lng: 18.210947};

                var mapOptions = {
                    center: center,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoom: 14,
                    scrollwheel: false,
                    zoomControl: false,
                    styles: mapStyle,
                    draggable: isDraggable
                };

                var map = new google.maps.Map(document.getElementById("map"), mapOptions);

                if (window.innerWidth < 767) {
                    map.setCenterWithOffset (center, 0, -73);
                } else if (window.innerWidth > 767 && window.innerWidth < 900) {
                    map.setCenterWithOffset (center, 0, -73);
                } else {
                    map.setCenterWithOffset (center, 0, -73);
                }

                mapInstances.push(map);

                var marker = null;
                if (mapIcon !== null) {
                    marker = new google.maps.Marker({
                        position: center,
                        map: map,
                        icon: mapIcon,
                        optimized: false
                    });
                } else {
                    marker = new google.maps.Marker({
                        position: center,
                        map: map,
                        icon: mapIcon,
                        optimized: false
                    });
                }
            }
        });
    }

    if (hasMapOnPage()) {
        setup();
    }

});
