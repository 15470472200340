$(function(){
    function setMobilEvents() {
        $(".hamb-icon").on('click', function () {
            if($(this).hasClass("active")){
                $(this).addClass('inactive');
            } else{
                $(this).removeClass('inactive');
            }
            $(this).toggleClass('active');
            $('.page-content').toggleClass('disabled-scroll');
            $(".mobile-menu").toggleClass("opened");
        });

        $(".mobile-menu-content ul").find('a').on('click', function (e) {
            e.preventDefault();
            $(".hamb-icon").removeClass('active');
            $('.page-content').removeClass('disabled-scroll');
            $(".mobile-menu").removeClass("opened");
            var eHref = $(this).attr("href").split('/')[1];
            $('html, body').animate({
                scrollTop: $(""+ eHref +"").offset().top
            }, 1200);
        });
    }
    function generateMobilDropMenu() {
        var mobilMenuDiv = $('<div/>', {
            class: 'mobile-menu'
        });

        var mobilMenuContent = $('<div/>', {
            class: 'mobile-menu-content'
        });

        $(mobilMenuDiv).insertAfter('.page-header');
        $(mobilMenuDiv).append(mobilMenuContent);
        $(mobilMenuContent).append($('.header-menu').children().clone()).html();
        $(mobilMenuContent).append($('.created-by').children().clone()).html();

        setMobilEvents();
    }
    generateMobilDropMenu();

    $(".hero button").on('click', function(){
        let $scrollDestination;
        if($("#apartmans").length > 0){
            $scrollDestination = $("#apartmans");
        } else {
            $scrollDestination = $("#advantages");
        }
        $('html, body').animate({
            scrollTop: $scrollDestination.offset().top
        }, 1000);
    })
});
