$(function(){
 showMore();
});

function showMore(){
    /*var events = ['click', 'touchstart'];*/
    $(document).on('click touchstart', '.advantages .slide button', function(){
        $(this).parents(".slide").toggleClass("show-more");
        if($(this).text() === "Mutass többet"){
            $(this).text("Mutass kevesebbet")
        } else if($(this).text() === "Mutass kevesebbet"){
            $(this).text("Mutass többet");
            //megkeresi az összes ilyen című elemet és becsukja azokat is
            var openedHtagText = $(this).parent().find('h3').text();
            $('.advantages-slider .owl-item h3').each(function(){
                if($(this).text() === openedHtagText){
                    if($(this).parent().hasClass('show-more')){
                        $(this).parent().removeClass('show-more');
                        $(this).parent().find('button').text('Mutass többet');
                    }
                }
            })
        }
    });
}