$(function () {
    var $advantagesCarousel = $(".advantages-slider");
    var $galleryCarousel = $(".gallery-slider");
    var $whyGreenCarousel = $(".answers");

    $galleryCarousel.each(function(index, el) {
        var enableLoop = $(el).data('loop') !== false;

        $(el).owlCarousel({
            margin: 14,
            loop: enableLoop,
            nav: true,
            dots: false,
            navText: [
                "<i class='icon icon-arrow-left'></i>",
                "<i class='icon icon-arrow-right'></i>"
            ],
            items: 1,
            responsive: {
                1024: {
                    items: 2,
                    margin: 500
                },
                1152:{
                    items: 2,
                    margin: 540
                },
                1280:{
                    items: 2,
                    margin: 710
                },
                1366:{
                    items: 2,
                    margin: 750
                },
                1440:{
                    items: 2,
                    margin: 785
                },
                1600:{
                    items: 2,
                    margin: 845
                },
                1800:{
                    items: 2,
                    margin: 940
                },
                1920:{
                    items: 2,
                    margin: 1038
                }
            }
        });
    });

    $advantagesCarousel.owlCarousel({
        margin: 10,
        loop: true,
        nav: true,
        dots: false,
        navText: [
            "<i class='icon icon-arrow-left'></i>",
            "<i class='icon icon-arrow-right'></i>"
        ],
        onInitialized: function() {
            $advantagesCarousel.find('.slide').addClass("is-showed");
        },
        responsive:{
            0:{
                items: 2,
                margin: 214
            },
            500:{
                items: 2,
                margin: 150
            },
            664:{
                items: 3,
                margin: 120
            },
            768: {
                items: 3,
                margin: 60
            },
            1024:{
                margin: 300
            }
        }
    });
    $whyGreenCarousel.owlCarousel({
        nav: false,
        dots: false,
        items: 1,
        loop: true,
        autoplay: true,
        onInitialized: function() {
            $whyGreenCarousel.find('p').addClass("is-showed");
        }
    });

    /**
     * Előnyök rész egyedi számláló csúszkával
     **/
    $(".advantages .owl-nav").prepend(
        "<div class='position'>" +
            "<div class='counter'>" +
                "<span class='current'>01</span>/<span class='total'>03</span>" +
            "</div>" +
            "<div class='line first'></div>" +
        "</div>");

    $advantagesCarousel.on('initialized.owl.carousel changed.owl.carousel', function(e){
        var current = e.relatedTarget.relative(e.relatedTarget.current());
        var total = e.relatedTarget.items().length;
        var $pos = $(".position");
        if(current === 0){
            $pos.removeClass("second third");
            $pos.addClass("first");
        }else if(current === 1){
            $pos.removeClass("first third");
            $pos.addClass("second");
        }else if(current === 2){
            $pos.removeClass("first second");
            $pos.addClass("third");
        }
        $(".current").text("0"+ (current + 1));
        $(".total").text("0" + total);
    });

    /**
     * Előnyök rész nyitott állapotú elem clone-ja is legyen nyitott lapozás eseménynél
     **/
    function cloneOpenedState(){
        $('.advantages-slider .owl-item button').each(function(){
            var openedHtagText = $(this).parent().find('h3').text();
            if($(this).text() === 'Mutass kevesebbet'){
                //Ha ki van nyitva, akkor megkeresi az összes ilyen című elemet és lenyitja
                $('.advantages-slider .owl-item h3').each(function(){
                    if($(this).text() === openedHtagText){
                        if(!$(this).parent().hasClass('show-more')){
                            $(this).parent().addClass('show-more');
                            $(this).parent().find('button').text('Mutass kevesebbet');
                        }
                    }
                })
            } else {
                if($(this).parent().hasClass('show-more')){
                    $(this).parent().removeClass('show-more');
                }
            }
        });
    }
    $('.advantages-slider .owl-nav button').each(function(){
       $(document).on('click', $(this), function(){
           cloneOpenedState();
       })
    });
    $advantagesCarousel.on('drag.owl.carousel', function(){
        cloneOpenedState();
    });

    /*if ($(window).width() > 767){
        /!**
         * Előnyök rész egyedi class előre és hátra lopozásnál
         **!/
        $advantagesCarousel.find(".owl-prev").on('click', function(){
            $(".actual-slide").removeClass("from-right from-left");
            $(".actual-slide").addClass("from-left");
        });
        $advantagesCarousel.find(".owl-next").on('click', function(){
            $(".actual-slide").removeClass("from-left from-right");
            $(".actual-slide").addClass("from-right");
        });

        /!**
         * Előnyök rész: tablet nézetől egy aktuális elembe tölti bele az első aktív owl-item tartalmát
         **!/
        $(".advantages-slider .owl-item").filter(".active")[0].style.display = "none";
        $(".actual-slide").html($(".advantages-slider .owl-item").filter(".active")[0].innerHTML);

        $advantagesCarousel.on('translated.owl.carousel', function(){
            $(".advantages-slider .owl-item").filter(".active")[0].style.display = "none";
            $(".advantages-slider .owl-item").filter(".active")[1].style.display = "block";
            $(".advantages-slider .owl-item").filter(".active")[2].style.display = "block";
            $(".advantages-slider .owl-item:not(.active)").css("display", "block");
            $(".actual-slide").html($(".advantages-slider .owl-item").filter(".active")[0].innerHTML);
        });
    }*/
});
